<template lang="html">
<base-modal :show-modal="showPrivacy" :modal-size="modalDevice" @close-modal="closeModal">
  <template v-slot:title>
    Privacy Policy
  </template>
  <template v-slot:body>
    <fieldset class="p-2 space-y-4">
      <p class="text-lg">Date Last Modified: March 24, 2025</p>

      <p>The Privacy Policy you are reading describes how Blitzoo, Inc. and its affiliates, parent companies, joint ventures and other corporate entities under common ownership (“Blitzoo”) treat the personal information you provide in connection with your use of Blitzoo’s social casino products and related services on either desktop, mobile or any other applicable devices (the “Service”).</p>

      <p>Please read this Privacy Policy carefully before accessing the Service. &nbsp;By accessing the Service or any associated websites, you agree to the collection, disclosure, storage and use of your personal information as outlined in this Privacy Policy. &nbsp;If you disagree with the terms of this Privacy Policy, do not access or use our Services.</p>

      <p>We also ask that you read the <span class="text-blue-500 underline" @click="termsModal">Terms of Service</span> (“Terms”) carefully before accessing the Service.</p>

      <p>The Privacy Policy is divided into two sections:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2">
        <li>SECTION 1 applies if you do not reside in the European Union</li>
        <li><a class="text-blue-500 underline" href="#section-two">SECTION 2 applies if you reside in the European Union or the United Kingdom</a></li>
      </ul>
      <br>

      <h2 class="text-xl">SECTION 1: NON-EUROPEAN UNION RESIDENTS</h2>
      <br>

      <h3 class="text-lg">What Personal Information Blitzoo Collects</h3>
      <hr>

      <p>Blitzoo collects a wide variety of information from our customers. &nbsp;The type of information that Blitzoo collects about you will depend on the nature of your interaction with Blitzoo or the Service, but may include:</p>

      <ul class="list-disc list-outside ml-4 grid gap-2">
        <li>Your first name and last name;</li>
        <li>Your social network user information (e.g. your social network identification number and any other information that is available if you have permitted the release of the information through the privacy settings on your social network account);</li>
        <li>Your mobile device’s unique identifier (UDID);</li>
        <li>Your telephone number;</li>
        <li>Your home address;</li>
        <li>Your mailing address;</li>
        <li>Your email address;</li>
        <li>Your age and date of birth;</li>
        <li>Your purchase transaction information if you make a purchase. &nbsp;We use a third-party service provider to process payments so we do not receive your billing information, but we do receive information about all payment transactions made;</li>
        <li>Information you provide in response to contests, surveys or other marketing outreach;</li>
        <li>User content generated by you, such as text and images as provided by you through the Service;</li>
        <li>Any information that you voluntarily post on the Service, including any postings or comments made as part of reviews or participation in forums;</li>
        <li>Other information as described in this Privacy Policy.</li>
      </ul>

      <p>To the extent you provide information about friends and family members, you agree to provide such individuals with this Privacy Policy and to obtain their consent to provide the information.</p>

      <h3 class="text-lg">How Your Personal Information Is Used</h3>
      <hr>

      <p>The following provides examples of how Blitzoo may use the personal information we collect:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2">
        <li>To process your purchases of licenses or services;</li>
        <li>To send you a confirmation, if applicable;</li>
        <li>To send you requested information;</li>
        <li>To send new product or service information;</li>
        <li>To respond to customer service requests;</li>
        <li>To administer your Account;</li>
        <li>To conduct research and analysis;</li>
        <li>To enforce our Terms;</li>
        <li>To display content based upon your interests;</li>
        <li>To help improve the Service;</li>
        <li>To monitor and analyze Service usage and trends and otherwise measure the effectiveness of the Service;</li>
        <li>To help customize the Service to better meet your needs;</li>
        <li>To market and promote the Service;</li>
        <li>To prevent fraudulent activity;</li>
        <li>To help diagnose technical problems;</li>
        <li>To answer any queries or questions you may have;</li>
        <li>To provide feedback to you regarding any reviews submitted;</li>
        <li>To administer your participation in a sweepstake, contest or similar promotion;</li>
        <li>To notify you about important changes to our Service; and</li>
        <li>As otherwise permitted by law or as we may notify you from time to time.</li>
      </ul>

      <h3 class="text-lg">How Blitzoo Collects Personal Information</h3>
      <hr>

      <p>The following describes the ways in which Blitzoo collects personal information:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>
          <span style="text-decoration: underline"><strong class="strong-span">Direct From You</strong></span><br>
          Personal information may be collected directly from you when you register an account with the Service, when you access and participate in message boards / forums or when you communicate with Customer Support or when you participate in surveys or questionnaires.
        </li>

        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">Passive Collection</strong></span><br>
            Passive personal information collection refers to personal information that is collected from your device(s) automatically and sent to our servers without your intervention. &nbsp;Examples of personal information collected passively are your IP address, referral data, information about your browser and the hardware you are using.
          </p>
          <br>

          <p>
            Blitzoo and service providers acting on behalf of Blitzoo may uses a variety of tracking tools to collect personal information. &nbsp;Such tracking tools may include, without limitation:
          </p>
          <br>

          <p>Cookies. &nbsp;Like many companies, we use "cookies" to collect personal information. &nbsp;Cookies are small data files placed on a Device when it is used to access the Service. &nbsp;Blitzoo, or our third-party advertising service providers, may place cookies or similar files on your Device for security purposes, to tell us whether you have visited the website before, if you are a new visitor or to otherwise facilitate site navigation, and to personalize your experience while using the Service. &nbsp;Cookies allow us to collect technical and navigational information, such as browser type, time spent using the Service and pages visited. &nbsp;Cookies also allow us to select which of our advertisements or offers are most likely to appeal to you and display them while you are using the Service. &nbsp;Cookies may enhance your online experience by saving your preferences. &nbsp;If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) to disable existing cookies; or (iii) to set your browser to automatically reject any cookies. &nbsp;However, please be aware that if you disable or reject cookies, some features and services on our Service may not work properly because we may not be able to recognize and associate you with your profile. &nbsp;In addition, the offers we provide when you visit us may not be as relevant to you or tailored to your interests.</p>
          <br>

          <p>Web Beacons. &nbsp;Certain areas of the Service may contain "web beacons" (also known as Internet tags, pixel tags, tracking beacons and clear GIFs). &nbsp;A web beacon is a transparent graphic image placed on a web page or in an email, which indicates that a page or email has been viewed or that an email has been forwarded. &nbsp;In addition, a web beacon allows third parties to obtain personal information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, the type of browser used to view the page, and the information in cookies set by the third party. &nbsp;A web beacon may also tell your browser to get content from another server.</p>
        </li>

        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">Third Parties</strong></span><br>
            Blitzoo may get personal information about you from third parties such as social media platforms or other service providers we use, and we will only use that personal information for the specific reason for which it was provided to us and in accordance with this Privacy Policy and such policy under which that personal information was collected.
          </p>
        </li>

        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">We Combine Information</strong></span><br>
            Blitzoo may combine personal information received from third parties with personal information collected from you.
          </p>
        </li>
      </ul>

      <h3 class="text-lg">Social Network Services or Mobile Devices</h3>
      <hr>

      <p>By accessing or connecting to the Service through a social network (such as Facebook), mobile device (such as cellular phones, iPads, tablets) or other third party platform or service you are authorizing Blitzoo to access, collect, store, and use in accordance with this Privacy Policy any and all personal information that you agreed the social network, mobile device or other third party platform and applications store could provide to Blitzoo through the social network/mobile device/third party platform Application Programming Interface (API) based on your settings on such social network, mobile device, applications store or platform.</p>

      <p>Your agreement takes place when you connect with the third-party network, applications store, platform or service via the Service, and/or when you connect with the Service, or “accept” or “allow” (or similar terms) the Service to access your personal information through a social network, mobile device or other third-party platform, applications store or service. &nbsp;Any personal information directly collected through a social network, mobile device or third-party platform or service will be imported, used and stored in a manner consistent with the Privacy Policy of the social network and/or mobile device in effect at the time of collection and in compliance with any specific privacy settings set by you of which we are aware.</p>

      <p>If you access our games from a social network, mobile device or other third-party platform, applications store or service, we recommend you to also read the applicable social network, mobile device or other third-party platform, applications store or service Terms of Service and Privacy Policy. &nbsp;If you are unclear about what personal information a social network is sharing with us, please go to the social networks where you play our games to find out more about their privacy settings.</p>

      <h3 class="text-lg">Sharing Personal Information</h3>
      <hr>

      <p>Blitzoo works with companies that help us provide the Service. &nbsp;These companies provide services such as processing credit card payments, sending emails on our behalf or analyzing customer information. &nbsp;In some cases, these companies have access to your personal information. &nbsp;They are not permitted to use your personal information for their own purposes.</p>

      <p>Blitzoo will not disclose your personal information to third parties except in the following circumstances:</p>

      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>We may disclose personal information in response to legal process; for example, in response to a court order or a subpoena;</li>
        <li>We may disclose personal information in response to a law enforcement agency's request, or where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms, or as otherwise required by law;</li>
        <li>We may transfer personal information about you if we are acquired by or merged with another company or in the unlikely event that Blitzoo is dissolved;</li>
        <li>If we need to share your personal information to provide the product or service you have requested;</li>
        <li>If we need to send the personal information to companies who work on behalf of Blitzoo to provide a product or service to you in conjunction with your use of the Blitzoo Service. &nbsp;Such companies do not have the right to use the personal information we provide to them beyond what is necessary to assist us;</li>
        <li>We may provide personal information to third parties for analytics purposes. &nbsp;Such personal information may not be used by the third party for any purpose other than analysis;</li>
        <li>We may provide personal information to Blitzoo’s affiliates, parent companies, joint venture and other corporate entities under common ownership;</li>
        <li>We may share personal information for other reasons we may describe to you.</li>
      </ul>

      <h3 class="text-lg">Blitzoo Online Advertising</h3>
      <hr>

      <p>Blitzoo may use online advertising, including displaying Blitzoo advertisements to you across the Internet on websites and in applications. &nbsp;We collect information about which advertisements are displayed, which advertisements are clicked on, and on which web page the advertisement was displayed.</p>

      <h3 class="text-lg">Location of Information</h3>
      <hr>

      <p>Personal information is stored in multiple locations both within and outside of the United States, including Canada and the United Kingdom.</p>

      <p>If you live outside of the United States, you understand and agree that Blitzoo may transfer your personal information to the United States and to such other jurisdictions.</p>

      <h3 class="text-lg">Security</h3>
      <hr>

      <p>The internet is not 100% secure. &nbsp;We cannot promise that your use of our sites will be completely safe.</p>

      <p>Blitzoo uses commercially reasonable efforts to ensure the security of the personal information we are provided with.</p>

      <p>Blitzoo keeps your personal information only as long as we need it for legitimate business purposes and to meet any legal requirements. &nbsp;Personal information used to make a decision that directly affects an individual will be kept for at least one year after such a decision. &nbsp;We have retention standards that meet these parameters. &nbsp;We destroy your personal information when it is no longer needed or required to be kept, or we remove your personally identifiable information to render it anonymous.</p>

      <p>This site and apps are subject to U.S. laws, which may not afford the same level of protection as those in your country.</p>

      <!-- California privacy rights -->
      <h3 class="text-lg">California Residents</h3>
      <hr>

      <p>If you are a California Resident, California law provides you with certain rights regarding your personal information, including the right to know about personal information collected, disclosed, or sold. &nbsp;You also have the right to request that we delete any or all of your personal information that we have collected from you, subject to certain exceptions, and the right not to be discriminated against if you exercise any of your rights under the California Consumer Privacy Act.</p>

      <p>Blitzoo may have collected the following categories of personal information from California residents in the past 12 months:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>Identifiers such as a picture, real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name.</li>
        <li>Commercial information, including products or services purchased, obtained, or considered from us.</li>
        <li>Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement.</li>
        <li>Geolocation data.</li>
        <li>Inferences drawn from any of the information identified above.</li>
      </ul>
      <br>

      <p>We collect this personal information from a number of sources, including from you directly and from third party online services such as Facebook and use it for the purposes disclosed in this Privacy Policy. &nbsp;We may have disclosed the categories of personal information identified above to certain third parties for a business purpose over the past twelve months, including internet service providers, data analytics providers, government entities, operating systems and platforms, and social networks. &nbsp;Where we disclose information for a business purpose, we enter into a contract that describes the business purpose and requires the recipient to keep the personal information confidential and not use it for any purpose except for performing the contract.</p>

      <p>Blitzoo has not sold personal information of California residents, including those under 16 years of age, to third parties for a commercial purpose in the preceding twelve months.</p>

      <p><strong><i>Right to Know</i></strong></p>

      <p>California residents have the right to request that Blitzoo disclose certain information about our collection and use of your personal information over the past 12 months. &nbsp;Once we receive and confirm your verifiable consumer request (see <a href="#request-to-know" class="text-blue-500 underline"><strong>Requests to Know and Delete</strong></a> below for instructions), we will disclose to you:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>The categories of personal information we collected about you.</li>
        <li>Our business or commercial purpose for collecting that personal information.</li>
        <li>The categories of third parties with whom we share that personal information.</li>
        <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
        <li>If we disclosed your personal information for a business purpose, a list identifying the personal information categories that each category of recipient obtained.</li>
      </ul>
      <br>

      <p><strong><i>Right to Delete</i></strong></p>

      <p>You have the right to request that Blitzoo delete the personal information that we collected from you and retain, subject to certain exceptions. &nbsp;Once we receive and confirm your verifiable consumer request (see <a href="#request-to-know" class="text-blue-500 underline"><strong>Requests to Know and Delete</strong></a> below for instructions), we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>

      <p id="request-to-know"><strong><i>Right to Know and Delete</i></strong></p>

      <p>To exercise your right to know and right to delete described above, please submit a verifiable consumer request to us via email to the email address <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a>. &nbsp;Include in the email the following information:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>First name</li>
        <li>Last name</li>
        <li>Email address</li>
      </ul>
      <br>

      <p>Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. &nbsp;If you wish to submit a verifiable consumer request on behalf of another individual, such as a minor child, we will also need sufficient information to verify that the individual is the person about whom we collected personal information and that you are authorized to submit the request on their behalf.</p>

      <p>You may only make a verifiable consumer request to know or delete your data twice within a 12-month period. &nbsp;Your request must:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or are an authorized representative.</li>
        <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
      </ul>
      <br>
      <p>We will need to verify your identity before processing most requests, which may require us to obtain additional personal information from you. We will only use the personal information provided in connection with a request to review and comply with the request.  If you do not provide this information, we may not be able to verify or complete your request.</p>

      <p>Making a verifiable consumer request does not require you to create an account with us.</p>

      <p><strong><i>Response Timing and Format</i></strong></p>

      <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. &nbsp;If we require more time, we will inform you of the reason for the extension.</p>

      <p>For data portability requests, we will select a format to provide your personal information that is readily useable.</p>

      <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. &nbsp;If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>

      <p>In certain circumstances, we may decline a request to exercise the rights described above.</p>

      <p>If you reside in California, you have the right to ask us one time each year if we have shared personal information with third parties for their direct marketing purposes. &nbsp;To make a request, please contact us via email at <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a> or write to us at the address listed below. &nbsp;Indicate in your email / letter that you are a California resident making a “Shine the Light” inquiry.</p>

      <p>
        Blitzoo, Inc.<br>
        10275 Wayzata Boulevard, Suite 300<br>
        Minnetonka, MN 55305<br>
        USA
      </p>

      <h3 class="text-lg" id="optOutOfAds">Do Not Sell My Personal Information and Opt-Out of Ads Personalization </h3>
      <hr>

      <p>Blitzoo doesn’t sell any personal information that identifies you personally like your name, email address, or phone number. &nbsp;We do work with advertising partners who use device identifiers as described in our Privacy Policy to show ads that are targeted to your interests. &nbsp;To opt out of allowing our advertising partners to use information about your app usage to show you personalized ads on your mobile device, please follow the instructions below:</p>

      <div class="border rounded-lg">
        <table class="max-w-full divide-y divide-gray-200">
          <tbody class="divide-y divide-gray-200">
            <tr>
              <td class="px-6 py-4 text-sm font-medium border-r">Apple Phone / Tablet</td>
              <td class="px-6 py-4 text-sm font-medium">On iOS 13 and earlier, navigate to “Settings” > “Privacy” > “Advertising” and enable/disable “Limit Ad Tracking.” <br><br>On iOS 14 and later, navigate to Settings” > “Privacy” > “Tracking” > and enable/disable “Allow Apps to Request to Track” or withdraw tracking permission for the applicable app.</td>
            </tr>
            <tr>
              <td class="px-6 py-4 text-sm font-medium border-r">Android Phone / Tablet</td>
              <td class="px-6 py-4 text-sm font-medium">Navigate to 'Settings' > “Privacy” > “Ads” and enable “Opt out of Ads Personalization” and select “Reset Advertising ID.”</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3 class="text-lg">Opt Out</h3>
      <hr>

      <p>Customers can opt out of receiving marketing emails from Blitzoo via a link that is contained in all marketing emails.</p>

      <p>If you wish to change how the personal information that you provide to Facebook is used by us, including the use of such information to send promotional messages to you, you may change your settings on your Facebook account.</p>

      <p>If you are interested in more information about tailored advertising and your choices to prevent third parties from delivering tailored web and mobile web advertising, you may visit the following websites:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>
          <a href="http://www.networkadvertising.org/choices/" target="_blank" class="text-blue-500 underline">Network Advertising Initiative Consumer Opt-Out Page</a>;
        </li>
        <li>
          <a href="http://www.aboutads.info/choices/" target="_blank" class="text-blue-500 underline">Digital Advertising Alliance Opt-Out Page for U.S.-based advertising</a>;
        </li>
        <li>
          <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank" class="text-blue-500 underline">Your Online Choices UK website for EU-based advertising</a>
        </li>
      </ul>
      <br>

      <p>These opt-out tools are provided by third parties, not Blitzoo, and may not be available with respect to all advertising that is presented to you.</p>

      <h3 class="text-lg">Age Restrictions</h3>
      <hr>

      <p>The Services are not intended for use by individuals under 18 years of age (or the legal age of majority in your jurisdiction, whichever is greater). &nbsp;As such, we do not knowingly collect any personal information from individuals under the age of 18 (or the legal age of majority in your jurisdiction, whichever is greater).</p>

      <p>If you become aware that your personal information has been provided to Blitzoo without your consent, or that the personal information of a minor has been provided to Blitzoo, please contact us at <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a> so that we can delete that personal information as quickly as possible.</p>

      <h3 class="text-lg">Requests Regarding your Information</h3>
      <hr>

      <p>Applicable privacy laws allow, to varying degrees, individuals the right to access and request the correction of errors or omissions in their personal information that is in Blitzoo’s custody or under Blitzoo’s control. &nbsp;If those laws apply to you, you may submit a request to our Privacy Officer at <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a>. &nbsp;We will respond to requests within the time allowed by applicable privacy laws and will make every effort to respond as accurately and completely as possible.</p>

      <h3 class="text-lg">Changes to Privacy Policy</h3>
      <hr>

      <p>Blitzoo may revise this Privacy Notice at any time, as we deem appropriate, consistent with the principles described in this notice, evolving business needs, or changes in applicable laws or regulations. &nbsp;Any changes made to the Privacy Policy will be effective from the date the changes are posted here.</p>

      <p>The date of the most recent change will be included in the Privacy Policy. &nbsp;Users of the Service are responsible for reviewing the Privacy Policy for any changes prior to accessing, using or downloading the Service. &nbsp;Your continued use of the Service after any change constitutes your acceptance of the change. &nbsp;If you do not understand, accept and agree to be bound by any changes made to the Privacy Policy you are no longer authorized to use the Service and you must cease using the Service immediately.</p>

      <h3 class="text-lg">Contact</h3>
      <hr>

      <p>If you have any questions about the Privacy Policy you can contact Blitzoo’s Privacy Officer at <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a> or by mail at the following address:</p>

      <p>
        Blitzoo, Inc.<br>
        10275 Wayzata Boulevard, Suite 300<br>
        Minnetonka, MN 55305<br>
        USA
      </p>
      <hr>
      <h2 class="text-xl" id="section-two">SECTION 2: EUROPEAN UNION RESIDENTS AND UNITED KINGDOM RESIDENTS</h2>

      <p>This section of the Blitzoo Privacy Policy applies to you if you are a resident of one of the Member States of the European Union or the United Kingdom and if you use the Service. &nbsp;If you do so, for instance by playing one of our games, and you entrust us with personal information or we collect personal information in the process, Blitzoo is the controller for that information.</p>

      <p>Section 2 of the Privacy Policy is merely informative. &nbsp;We are not seeking your consent through this Privacy Policy. &nbsp;We may ask for your consent for certain processing activities but will do so through separate notice and consent forms where appropriate.</p>

      <h3 class="text-lg">What Personal Information Blitzoo Collects</h3>
      <hr>

      <p>Blitzoo collects a wide variety of information from our customers. &nbsp;The type of information that Blitzoo collects about you will depend on the nature of your interaction with Blitzoo or the Service, but may include:</p>

      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>Your first name and last name;</li>
        <li>Your social network user information (e.g. your social network identification number and any other information that is available if you have permitted the release of the information through the privacy settings on your social network account);</li>
        <li>Your mobile device’s unique identifier (UDID);</li>
        <li>Your telephone number;</li>
        <li>Your home address;</li>
        <li>Your mailing address;</li>
        <li>Your email address;</li>
        <li>Your age and date of birth;</li>
        <li>Your purchase transaction information if you make a purchase. &nbsp;We use a third-party service provider to process payments so we do not receive your billing information, but we do receive information about all payment transactions made;</li>
        <li>Information you provide in response to contests, surveys or other marketing outreach;</li>
        <li>User content generated by you, such as text and images as provided by you through the Service;</li>
        <li>Any information that you voluntarily post on the Service, including any postings or comments made as part of reviews or participation in forums;</li>
        <li>Other information as described in this Privacy Policy.</li>
      </ul>
      <br>

      <p>To the extent you provide information about friends and family members, you agree to provide such individuals with this Privacy Policy and to obtain their consent to provide the information.</p>

      <h3 class="text-lg">How Your Personal Information Is Used</h3>
      <hr>

      <p>The purposes for which we collect, store, use and disclose information include:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>Providing you with the Service, including allowing you to register and correctly identify yourself, as well as providing you with the core game experience;</li>
        <li>At your request, responding to inquiries;</li>
        <li>With your consent, contacting you to let you know that you have been invited by one of our players to a Blitzoo Service;</li>
        <li>Based on a legitimate business interest, providing you with, or communicating with you about Blitzoo-specific advertising, products, events and promotional information in connection with the Service and other marketing outreach;</li>
        <li>Based on our legitimate business interest, for business research and the improvement of our Service or the development of new services;</li>
        <li>Based on our legitimate interest, detecting and preventing fraud or misuse of our Service;</li>
        <li>To comply with our legal obligations, establish and defend legal claims, and in some cases, our basis for processing will be because you have consented to our use of your information;</li>
        <li>For purposes unrelated to those described in this Privacy Policy by first notifying you and, where required, offering you a choice as to whether or not we may use your personal information in this different manner.</li>
      </ul>

      <h3 class="text-lg">How Blitzoo Collects Personal Information</h3>
      <hr>

      <p>The following describes the ways in which Blitzoo collects personal information:</p>

      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">Direct From You</strong></span><br>
            Personal information may be collected directly from you when you register an account with the Service, when you access and participate in message boards / forums or when you communicate with Customer Support or when you participate in surveys or questionnaires.
          </p>
        </li>

        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">Passive Collection</strong></span><br>
            Passive personal information collection refers to personal information that is collected from your device(s) automatically and sent to our servers without your intervention. &nbsp;Examples of personal information collected passively are your IP address, referral data, information about your browser and the hardware you are using.
          </p>

          <p>
            Blitzoo and service providers acting on behalf of Blitzoo may uses a variety of tracking tools to collect personal information. &nbsp;Such tracking tools may include, without limitation:
          </p>

          <p>Cookies. &nbsp;Like many companies, we use "cookies" to collect personal information. &nbsp;Cookies are small data files placed on a Device when it is used to access the Service. &nbsp;Blitzoo, or our third-party advertising service providers, may place cookies or similar files on your Device for security purposes, to tell us whether you have visited the website before, if you are a new visitor or to otherwise facilitate site navigation, and to personalize your experience while using the Service. &nbsp;Cookies allow us to collect technical and navigational information, such as browser type, time spent using the Service and pages visited. &nbsp;Cookies also allow us to select which of our advertisements or offers are most likely to appeal to you and display them while you are using the Service. &nbsp;Cookies may enhance your online experience by saving your preferences. &nbsp;If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) to disable existing cookies; or (iii) to set your browser to automatically reject any cookies. &nbsp;However, please be aware that if you disable or reject cookies, some features and services on our Service may not work properly because we may not be able to recognize and associate you with your profile. &nbsp;In addition, the offers we provide when you visit us may not be as relevant to you or tailored to your interests.</p>

          <p>Web Beacons. &nbsp;Certain areas of the Service may contain "web beacons" (also known as Internet tags, pixel tags, tracking beacons and clear GIFs). &nbsp;A web beacon is a transparent graphic image placed on a web page or in an email, which indicates that a page or email has been viewed or that an email has been forwarded. &nbsp;In addition, a web beacon allows third parties to obtain personal information such as the IP address of the computer that downloaded the page on which the beacon appears, the URL of the page on which the beacon appears, the time the page containing the beacon was viewed, the type of browser used to view the page, and the information in cookies set by the third party. &nbsp;A web beacon may also tell your browser to get content from another server.</p>
        </li>

        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">Third Parties</strong></span><br>
            Blitzoo may get personal information about you from third parties such as social media platforms or other service providers we use, and we will only use that personal information for the specific reason for which it was provided to us and in accordance with this Privacy Policy and such policy under which that personal information was collected.
          </p>
        </li>

        <li>
          <p>
            <span style="text-decoration: underline"><strong class="strong-span">We Combine Information</strong></span><br>
            Blitzoo may combine personal information received from third parties with personal information collected from you.
          </p>
        </li>
      </ul>

      <h3 class="text-lg">Your Choices and Rights</h3>
      <hr>

      <p>In accordance with applicable law, you may be entitled to request restriction of processing of your information, and to request access to, correction, erasure, and portability of your information. &nbsp;If you are aware of changes or inaccuracies in your information, you should inform us of such changes so that your information may be updated or corrected. &nbsp;If we collect or process information based on your consent, you have the right to withdraw your consent at all times. &nbsp;You can terminate your account at any time which will remove your profile and other personal information from view. &nbsp;We will use reasonable efforts promptly to amend or delete your records as requested. &nbsp;Your information will generally be permanently deleted or anonymized one month after your request to terminate the account. &nbsp;In some cases we may be legally required to maintain certain records of your personal information, in which case we may not be permitted to delete your information. Please contact <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a> to terminate your account, exercise any of your rights or for any other questions about your personal information.</p>

      <p>If you are unhappy about how we handle your personal information or how we dealt with your request, you have the right to lodge a complaint with the data protection authority in your Member State of residence.</p>

      <h3 class="text-lg">Social Network Services or Mobile Devices</h3>
      <hr>

      <p>By accessing or connecting to the Service through a social network (such as Facebook), mobile device (such as cellular phones, iPads, tablets) or other third party platform or service you are authorizing Blitzoo to access, collect, store, and use in accordance with this Privacy Policy any and all personal information that you agreed the social network, mobile device or other third party platform and applications store could provide to Blitzoo through the social network/mobile device/third party platform Application Programming Interface (API) based on your settings on such social network, mobile device, applications store or platform.</p>

      <p>Your agreement takes place when you connect with the third-party network, applications store, platform or service via the Service, and/or when you connect with the Service, or “accept” or “allow” (or similar terms) the Service to access your personal information through a social network, mobile device or other third-party platform, applications store or service. &nbsp;Any personal information directly collected through a social network, mobile device or third-party platform or service will be imported, used and stored in a manner consistent with the Privacy Policy of the social network and/or mobile device in effect at the time of collection and in compliance with any specific privacy settings set by you of which we are aware.</p>

      <p>If you access our games from a social network, mobile device or other third-party platform, applications store or service, we recommend you to also read the applicable social network, mobile device or other third-party platform, applications store or service Terms of Service and Privacy Policy. &nbsp;If you are unclear about what personal information a social network is sharing with us, please go to the social networks where you play our games to find out more about their privacy settings.</p>

      <h3 class="text-lg">Sharing Personal Information</h3>
      <hr>

      <p>Blitzoo works with companies that help us provide the Service. &nbsp;These companies provide services such as processing credit card payments, sending emails on our behalf or analyzing customer information. &nbsp;In some cases, these companies have access to your personal information. &nbsp;They are not permitted to use your personal information for their own purposes.</p>

      <p>Blitzoo will not disclose your personal information to third parties except in the following circumstances:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>We may disclose personal information in response to legal process; for example, in response to a court order or a subpoena;</li>
        <li>We may disclose personal information in response to a law enforcement agency's request, or where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms, or as otherwise required by law;</li>
        <li>We may transfer personal information about you if we are acquired by or merged with another company or in the unlikely event that Blitzoo is dissolved;</li>
        <li>If we need to share your personal information to provide the product or service you have requested;</li>
        <li>If we need to send the personal information to companies who work on behalf of Blitzoo to provide a product or service to you in conjunction with your use of the Blitzoo Service. &nbsp;Such companies do not have the right to use the personal information we provide to them beyond what is necessary to assist us;</li>
        <li>We may provide personal information to third parties for analytics purposes. &nbsp;Such personal information may not be used by the third party for any purpose other than analysis;</li>
        <li>We may provide personal information to Blitzoo’s affiliates, parent companies, joint venture and other corporate entities under common ownership;</li>
        <li>We may share personal information for other reasons we may describe to you.</li>
      </ul>
      <br>

      <p>We will put in place contractual protections to keep your information secure as required by applicable data protection laws.</p>

      <h3 class="text-lg">Blitzoo Online Advertising</h3>
      <hr>

      <p>Blitzoo may use online advertising, including displaying Blitzoo advertisements to you across the Internet on websites and in applications. &nbsp;We collect information about which advertisements are displayed, which advertisements are clicked on, and on which web page the advertisement was displayed.</p>

      <h3 class="text-lg">Do Not Sell My Personal Information and Opt-Out of Ads Personalization </h3>
      <hr>

      <p>Blitzoo doesn’t sell any personal information that identifies you personally like your name, email address, or phone number. &nbsp;We do work with advertising partners who use device identifiers as described in our Privacy Policy to show ads that are targeted to your interests. &nbsp;To opt out of allowing our advertising partners to use information about your app usage to show you personalized ads on your mobile device, please follow the instructions below:</p>

      <div class="border rounded-lg">
        <table class="max-w-full divide-y divide-gray-200">
          <tbody class="divide-y divide-gray-200">
            <tr>
              <td class="px-6 py-4 text-sm font-medium border-r">Apple Phone / Tablet</td>
              <td class="px-6 py-4 text-sm font-medium">On iOS 13 and earlier, navigate to “Settings” > “Privacy” > “Advertising” and enable/disable “Limit Ad Tracking.” <br><br>On iOS 14 and later, navigate to Settings” > “Privacy” > “Tracking” > and enable/disable “Allow Apps to Request to Track” or withdraw tracking permission for the applicable app.</td>
            </tr>
            <tr>
              <td class="px-6 py-4 text-sm font-medium border-r">Android Phone / Tablet</td>
              <td class="px-6 py-4 text-sm font-medium">Navigate to 'Settings' > “Privacy” > “Ads” and enable “Opt out of Ads Personalization” and select “Reset Advertising ID.”</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3 class="text-lg">Location of Information</h3>
      <hr>

      <p>Personal information is stored in multiple locations both within and outside of the United States, including Canada and the United Kingdom.</p>

      <p>If you live outside of the United States, you understand and agree that Blitzoo may transfer your personal information to the United States and to such other jurisdictions.</p>

      <h3 class="text-lg">Security</h3>
      <hr>

      <p>The internet is not 100% secure. &nbsp;We cannot promise that your use of our sites will be completely safe.</p>

      <p>Blitzoo uses commercially reasonable efforts to ensure the security of the personal information we are provided with.</p>

      <p>Blitzoo keeps your personal information only as long as we need it for legitimate business purposes and to meet any legal requirements. &nbsp;Personal information used to make a decision that directly affects an individual will be kept for at least one year after such a decision. &nbsp;We have retention standards that meet these parameters. &nbsp;We destroy your personal information when it is no longer needed or required to be kept, or we remove your personally identifiable information to render it anonymous.</p>

      <p>This site and apps are subject to U.S. laws, which may not afford the same level of protection as those in your country.</p>

      <h3 class="text-lg">Opt Out</h3>
      <hr>

      <p>Customers can opt out of receiving marketing emails from Blitzoo via a link that is contained in all marketing emails.</p>

      <p>If you wish to change how the personal information that you provide to Facebook is used by us, including the use of such information to send promotional messages to you, you may change your settings on your Facebook account.</p>

      <p>If you are interested in more information about tailored advertising and your choices to prevent third parties from delivering tailored web and mobile web advertising, you may visit the following websites:</p>
      <ul class="list-disc list-outside ml-4 grid gap-2 grid gap-2">
        <li>
          <a href="http://www.networkadvertising.org/choices/" target="_blank" class="text-blue-500 underline">Network Advertising Initiative Consumer Opt-Out Page</a>;
        </li>
        <li>
          <a href="http://www.aboutads.info/choices/" target="_blank" class="text-blue-500 underline">Digital Advertising Alliance Opt-Out Page for U.S.-based advertising</a>;
        </li>
        <li>
          <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank" class="text-blue-500 underline">Your Online Choices UK website for EU-based advertising</a>
        </li>
      </ul>
      <br>

      <p>These opt-out tools are provided by third parties, not Blitzoo, and may not be available with respect to all advertising that is presented to you.</p>

      <h3 class="text-lg">Age Restrictions</h3>
      <hr>

      <p>The Services are not intended for use by individuals under 18 years of age (or the legal age of majority in your jurisdiction, whichever is greater). &nbsp;As such, we do not knowingly collect any personal information from individuals under the age of 18 (or the legal age of majority in your jurisdiction, whichever is greater).</p>

      <p>If you become aware that your personal information has been provided to Blitzoo without your consent, or that the personal information of a minor has been provided to Blitzoo, please contact us at <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a> so that we can delete that personal information as quickly as possible.</p>

      <h3 class="text-lg">Changes to Privacy Policy</h3>
      <hr>

      <p>Blitzoo may revise this Privacy Notice at any time, as we deem appropriate, consistent with the principles described in this notice, evolving business needs, or changes in applicable laws or regulations. &nbsp;Any changes made to the Privacy Policy will be effective from the date the changes are posted here.</p>

      <p>The date of the most recent change will be included in the Privacy Policy. &nbsp;Users of the Service are responsible for reviewing the Privacy Policy for any changes prior to accessing, using or downloading the Service. &nbsp;Your continued use of the Service after any change constitutes your acceptance of the change. &nbsp;If you do not understand, accept and agree to be bound by any changes made to the Privacy Policy you are no longer authorized to use the Service and you must cease using the Service immediately.</p>

      <h3 class="text-lg">Contact</h3>
      <hr>

      <p>If you have any questions about the Privacy Policy you can contact Blitzoo’s Privacy Officer at <a href="mailto:support@blitzoo.com" target="_top" class="text-blue-500 underline">support@blitzoo.com</a> or by mail at the following address:</p>

      <p>
        Blitzoo, Inc.<br>
        10275 Wayzata Boulevard, Suite 300<br>
        Minnetonka, MN 55305<br>
        USA
      </p>

      <!-- <br>
      <p><strong class="strong-span">Last Updated: </strong>Aug 27, 2021</p> -->
    </fieldset>
  </template>
  <template v-slot:footer>
    <div class="mt-4 flex flex-row-reverse">
      <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 dark:bg-green-800 text-base font-medium text-white dark:text-gray-100 focus:outline-none ml-3 sm:w-auto sm:text-sm"
        @click="closeModal"
      >
        OK
      </button>
      <!-- <button
        type="button"
        class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        @click="closeModal"
      >
        Cancel
      </button> -->
    </div>
  </template>
</base-modal>
</template>

<script>
import BaseModal from './ui/BaseModal.vue'
export default {
  props: ['show-privacy', 'modal-device'],
  emits: ['close-modal', 'terms-modal'],
  components: {
    BaseModal
  },
  methods: {
    closeModal () {
      this.$emit('close-modal')
    },
    termsModal () {
      this.$emit('terms-modal')
    }
  },
  mounted () {
    if (this.$store.getters.getOptOut) {
      setTimeout(() => {
        document.getElementById('optOutOfAds').scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }, 1000)
    }
  },
  unmounted () {
    this.$store.dispatch('changeOptOut', false)
  }
}
</script>

<style lang="css" scoped>
</style>
